<template>
  <el-cascader
    v-if="visible"
    ref="area"
    v-bind="$attrs"
    v-model="value"
    :options="areaList"
    :props="props"
    popper-class="area-select_proper"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
/**
 * 地区级联选择器
 */
// component
import { Cascader } from 'element-ui';
// api
import { getAreaList } from '@/api/back-stage';

export default {
  name: 'areaSelect',

  components: {
    [Cascader.name]: Cascader,
  },

  props: {
    defaultValue: Array,
    // 默认级别（0国家-1省份-2市级-3区级）
    defaultLevel: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      visible: false,
      areaList: [],
      value: [],
      // 配置项
      props: {
        value: 'areaCode',
        label: 'areaName',
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value } = node;
          const params = value ? { parentAreaCode: value } : {};

          getAreaList(params).then((res) => {
            res.result.forEach((item) => {
              item.leaf = level >= this.defaultLevel;
            });
            resolve(res.result);
          });
        },
      },
    };
  },

  watch: {
    defaultValue: {
      immediate: true,
      handler(val) {
        this.init(val);
      },
    },
  },

  methods: {
    handleChange(val) {
      let checkNode = this.$refs.area.getCheckedNodes()[0];
      this.$emit('select', val);
      this.$emit('getCheckNodesData', getAreaItem(checkNode));

      function getAreaItem(node) {
        let { level, data } = node;
        let areas = new Array(data);
        let currentNode = node;
        for (let i = 1; i < level; i++) {
          areas.unshift(currentNode.parent.data);
          currentNode = currentNode.parent;
        }
        return areas;
      }
    },

    resetData() {
      this.areaList = [];
      this.value = [];
    },

    resetValue() {
      this.value = [];
    },

    init(data) {
      this.visible = false;
      let res = [];
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i]) {
            res.push(Number(data[i]));
          }
        }
      }
      this.value = res;
      this.$nextTick(() => {
        this.visible = true;
      });
    },
  },
};
</script>

<style lang="scss">
.area-select_proper {
  .el-scrollbar__wrap {
    height: 204px;
  }
}
</style>
