// api
import { saveAllConnectConfig } from '@/api/back-stage';

export default {
  data() {
    return {
      visible: false,
      // 关联表单
      form: {
        id: '',
        level: 0,
        countryCode: '',
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        areaPlatFormName: '',
        areaPlatFormUrl: '',
        appKey: '',
        appId: '',
      },
      // 默认选中地区
      areaSelectName: '',
      areaDefaultValue: [],
      rules: {
        countryCode: [{ type: 'number', required: true, message: '地区不能为空', trigger: 'change' }],
        areaPlatFormName: [{ required: true, message: '区域全连接平台名称不能为空', trigger: 'blur' }],
        areaPlatFormUrl: [{ required: true, message: '区域全连接平台地址不能为空', trigger: 'blur' }],
        appKey: [{ required: true, message: 'APP KEY 不能为空', trigger: 'blur' }],
        appId: [{ required: true, message: 'APP ID 不能为空', trigger: 'blur' }],
      },

      // 地区级别列表
      areaLevelList: [
        { level: 0, levelName: '国家级' },
        { level: 1, levelName: '省级' },
        { level: 2, levelName: '市级' },
        { level: 3, levelName: '县/区级' },
      ],
    };
  },
  methods: {
    // 新增关联
    handleAdd() {
      this.visible = true;
    },

    // 编辑关联
    handleEdit(row) {
      this.visible = true;
      let {
        id,
        level,
        areaPlatFormName,
        areaPlatFormUrl,
        appKey,
        appId,
        countryCode,
        provinceCode,
        cityCode,
        areaCode,
        areaName,
      } = row;
      this.form = {
        id,
        level,
        countryCode,
        provinceCode,
        cityCode,
        areaCode,
        areaPlatFormName,
        areaPlatFormUrl,
        appKey,
        appId,
      };
      this.areaSelectName = areaName;
      this.areaDefaultValue = [countryCode, provinceCode, cityCode, areaCode];
    },

    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { id, ...form } = this.form;
          let params = id ? this.form : form;
          saveAllConnectConfig(params).then(() => {
            this.$message.success('保存成功！');
            this.visible = false;
            this.getDataList();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 修改地区等级
    changeAreaLevel() {
      this.$refs.areaSelect.resetData();
      this.setAreaInfo([]);
    },

    // 获取地区信息
    setAreaInfo(area) {
      let [countryCode, provinceCode, cityCode, areaCode] = area;
      this.form.countryCode = countryCode;
      this.form.provinceCode = provinceCode;
      this.form.cityCode = cityCode;
      this.form.areaCode = areaCode;
    },

    // 表单重置
    resetForm() {
      this.$refs.form.resetFields();
      this.$refs.areaSelect.resetValue();
      this.areaSelectName = '';
      for (let i in this.form) {
        this.form[i] = i === 'level' ? 0 : '';
      }
    },
  },
};
